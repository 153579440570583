import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';
import Form from './2023cahpsAssessmentForm';
import Hero from './2023cahpsAssessmentHero';

const bgSizing = {
  bottom: 0,
  position: 'absolute',
  top: 0,
  zIndex: -1,
};

const heroSizing = {
  maxWidth: '400px',
};

/**
 * CAHPSAssessment2023 page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = () => {
  const {
    contentfulCahpsInterventionRecommendation: {
      background,
      confirmationMessage,
      content: {
        childMarkdownRemark: { html },
      },
      header,
      sampleReport,
      subheader,
    },
  } = useStaticQuery(graphql`
    query CAHPSAssessment2023 {
      contentfulCahpsInterventionRecommendation {
        header
        subheader
        content {
          childMarkdownRemark {
            html
          }
        }
        confirmationMessage
        background {
          fluid(quality: 95, maxWidth: 1900) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        sampleReport {
          fluid(quality: 95, maxWidth: 512) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.contactMainShowcase,
        main.contactMainShowcaseCol,
        main.contactInnerShowcaseXl
      )}
    >
      {background && background.fluid ? (
        <Img
          fluid={background.fluid}
          alt=""
          aria-hidden="true"
          style={bgSizing}
          className={base.w100}
        />
      ) : null}
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyCenter,
          base.w100,
          main.contactInnerShowcase,
          main.contactInnerShowcaseNone
        )}
      >
        {sampleReport && sampleReport.fluid ? (
          <Img
            fluid={sampleReport.fluid}
            alt=""
            aria-hidden="true"
            loading="eager"
            style={heroSizing}
            imgStyle={heroSizing}
            className={base.w100}
          />
        ) : null}
        <h1
          className={cx(
            base.mt3,
            base.tc,
            main.contactImgCopyLg,
            main.headingLight,
            theme.colorTextGrey
          )}
        >
          <CleanHTML html={header} />
        </h1>
      </div>
      <div
        className={cx(base.h100, main.contactDivider, main.contactDividerDark)}
      />
      <div
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsCenter,
          base.justifyCenter,
          base.w100,
          main.contactInner,
          main.contactInnerShowcase,
          main.contactInnerShowcaseNone,
          main.contactInnerShowcaseCol
        )}
      >
        <Hero subheader={subheader} body={html} sampleReport={sampleReport} />
        <Form confirmation={confirmationMessage} />
      </div>
    </section>
  );
};

export default Main;
