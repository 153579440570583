import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validator from 'validator';
import { NetlifyForm, Honeypot } from 'react-netlify-forms';
import { event } from 'react-ga';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * CAHPSAssessment2023 page Form component.
 * @component
 *
 * @returns {component} - <Form placeholder={string} btn={string} />
 */

const Form = ({ confirmation }) => {
  // eslint-disable-next-line no-unused-vars
  const [formName, setFormName] = useState('CAHPS Intervention Recommendation');
  const [sent, setSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const validateEmail = evt => {
    const email = evt.target.value;

    if (email === '' || validator.isEmail(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sent) {
      event({
        category: 'Form',
        action: 'Submission',
        label: formName,
      });
    }
  }, [sent]);

  return (
    <div
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100
      )}
    >
      <NetlifyForm
        name={formName}
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.w100,
          main.contactFormShowcase
        )}
        honeypotName="bot-field"
      >
        {({ handleChange, success, submitting }) => {
          if (success) {
            setSent(true);
          }

          return sent ? (
            <div
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.contactFormWrap
              )}
            >
              <p
                data-testid="confirmation"
                className={cx(
                  base.ma0,
                  base.tc,
                  base.w100,
                  main.contactFormConfirmation,
                  theme.colorTextGrey,
                  theme.title2
                )}
              >
                {confirmation}
              </p>
            </div>
          ) : (
            <>
              <Honeypot />
              <div
                className={cx(
                  base.flex,
                  base.itemsStart,
                  base.justifyStart,
                  base.w100
                )}
              >
                <div
                  className={cx(
                    base.flex,
                    base.flexColumn,
                    base.itemsStart,
                    base.justifyStart,
                    base.mr3,
                    base.w100,
                    main.contactFormInnerShow
                  )}
                >
                  <label
                    htmlFor="firstName"
                    className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                  >
                    First Name
                  </label>
                  <input
                    id="firstName"
                    data-testid="firstName"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldTall
                    )}
                    type="text"
                    name="firstName"
                    inputMode="firstName"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={cx(
                    base.flex,
                    base.flexColumn,
                    base.itemsStart,
                    base.justifyStart,
                    base.w100,
                    main.contactFormInnerShow
                  )}
                >
                  <label
                    htmlFor="lastName"
                    className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                  >
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    data-testid="lastName"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldTall
                    )}
                    type="text"
                    name="lastName"
                    inputMode="lastName"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div
                className={cx(
                  base.flex,
                  base.flexColumn,
                  base.itemsStart,
                  base.justifyStart,
                  base.relative,
                  base.w100
                )}
              >
                <label
                  htmlFor="email"
                  className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                >
                  Company Email
                </label>
                <input
                  id="email"
                  data-testid="email"
                  className={cx(
                    base.w100,
                    main.formInputField,
                    main.formInputFieldLg,
                    main.formInputFieldTall
                  )}
                  type="text"
                  name="email"
                  inputMode="email"
                  required
                  onChange={evt => {
                    handleChange(evt);
                    validateEmail(evt);
                  }}
                  data-valid={`${!invalidEmail}`}
                />
                <span
                  className={cx(
                    main.emailValidationError,
                    main.emailValidationErrorTall
                  )}
                  aria-hidden={!invalidEmail}
                  style={{ display: invalidEmail ? 'block' : 'none' }}
                >
                  Invalid Email
                </span>
              </div>
              <div
                className={cx(
                  base.flex,
                  base.itemsStart,
                  base.justifyStart,
                  base.w100
                )}
              >
                <div
                  className={cx(
                    base.flex,
                    base.flexColumn,
                    base.itemsStart,
                    base.justifyStart,
                    base.mr3,
                    base.w100,
                    main.contactFormInnerShow
                  )}
                >
                  <label
                    htmlFor="planName"
                    className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                  >
                    Plan Name
                  </label>
                  <input
                    id="planName"
                    data-testid="planName"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldTall
                    )}
                    type="text"
                    name="planName"
                    inputMode="planName"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={cx(
                    base.flex,
                    base.flexColumn,
                    base.itemsStart,
                    base.justifyStart,
                    base.w100,
                    main.contactFormInnerShow
                  )}
                >
                  <label
                    htmlFor="hContracts"
                    className={cx(base.mb1, theme.colorTextGrey, theme.title4)}
                  >
                    H-Contract(s) to Include
                  </label>
                  <input
                    id="hContracts"
                    data-testid="hContracts"
                    className={cx(
                      base.w100,
                      main.formInputField,
                      main.formInputFieldTall
                    )}
                    type="text"
                    name="hContracts"
                    inputMode="hContracts"
                    placeholder="H1234, H2345"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button
                type="submit"
                data-testid="submit"
                className={cx(
                  base.w100,
                  main.button,
                  main.contactButton,
                  main.contactButtonSm,
                  theme.colorWhite,
                  theme.dpBtnBlue
                )}
                disabled={submitting || sent || invalidEmail}
              >
                {submitting ? 'Sending...' : sent ? 'Sent!' : 'Submit'}
              </button>
            </>
          );
        }}
      </NetlifyForm>
    </div>
  );
};

Form.propTypes = {
  confirmation: PropTypes.string.isRequired,
};

export default Form;
