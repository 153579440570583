import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

/**
 * CAHPSAssessment2023 page Hero component.
 * @component
 *
 * @returns {component} - <Hero subheader={string} body={string} />
 */

const Hero = ({ subheader, body }) => (
  <div
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyBetween,
      base.mb3,
      base.w100,
      main.memberWrap,
      main.memberWrapShow
    )}
  >
    <h3 className={cx(base.mb3, base.tc)}>
      <CleanHTML html={subheader} />
    </h3>
    <CleanHTML html={body} />
  </div>
);

Hero.propTypes = {
  subheader: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default Hero;
