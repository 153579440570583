import React from 'react';
import PropTypes from 'prop-types';
import { initialize } from 'react-ga';

import Layout from '../components/layout';
import Main from '../components/2023-cahps-assessment/2023cahpsAssessmentMainContent';

/**
 * CAHPSAssessment2023 page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <CAHPSAssessment2023 location={string} />
 */

const CAHPSAssessment2023 = ({ location }) => {
  initialize('UA-33936956-01', {
    debug: true,
    titleCase: false,
  });

  return (
    <Layout location={location} showcase>
      <Main />
    </Layout>
  );
};

CAHPSAssessment2023.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default CAHPSAssessment2023;
